ul.list {
  list-style: none;
  padding: 0;

  &.horizontal {
    @media (min-width: 992px) {
      display: flex;
      justify-content: stretch;
      li {
        flex-grow: 1;

        display: inline-block;
        padding: 0;
      }
    }
  }

  &.visit {
    padding-left: 0.5rem;

    i {
      transform: scale(0.5);
    }
  }

  li {
    padding: 0.5rem 0;

    i {
      width: 1.5rem;
      text-align: center;
    }
  }
}
