@import "../UI.module";

$btnPaddingTop: 0.75rem;
$btnPaddingLeft: 1rem;
$btnHeight: $fontSize + 2 * $btnPaddingTop;
@mixin button($clr: $primaryColor, $clr2: $backgroundColor, $fill: true) {
  $darkColor: darken($clr, 20%);

  font-size: $fontSize;
  border-radius: $borderRadius;
  height: $btnHeight;
  padding: 0 $btnPaddingLeft;

  color: $clr;
  background: $clr2;
  @if $fill {
    color: $clr2;
    background: $clr;
  }

  @include outlined($clr);

  &:active:not(:disabled) {
    color: $clr2;
    background-color: $darkColor;
    @include outlined($darkColor);
  }

  &:disabled {
    @include disabled();
    background-color: darken($backgroundColor, 1);
  }
  cursor: pointer;
}

.btn {
  @include button();

  position: relative;
  transition: all 0.5s;
}

.btn.secondary,
.btn.bare {
  @include button($primaryColor, $backgroundColor, false);

  &:hover:not(.bare) {
    @include button();
  }
}

.btn.pressed {
  @include pressed();
}

.btn.animated {
  @include button($primaryColor, $backgroundColor, false);

  position: relative;
  transition: all 0.5s;

  @media (max-width: 992px) {
    @include button();

    span.h {
      display: none !important;
    }
  }

  overflow: hidden;
  z-index: 1;

  span.h {
    z-index: -1;

    display: block;
    position: absolute;
    border-radius: 50%;
    background: $primaryColor;

    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    transition: all 0.5s;

    outline: 100px solid $backgroundColor; // fix1
  }

  &:active:not(:disabled) {
    span.h {
      background-color: darken($primaryColor, 20%);
    }
  }

  &:hover:not(:disabled) {
    color: $backgroundColor;

    span.h {
      width: 100% * 2.25;
      height: 100% * 2.25;
    }

    background-color: $primaryColor; // fix1
  }
}
