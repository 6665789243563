@use "sass:math";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";

@import "~bootstrap/scss/functions";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/* FIGMA Values
$grid-breakpoints: (
        xs: 0,
        sm: 374px,
        md: 768px,
        lg: 1400px,
        xl: 1920px,
        xxl: 2560px
);
$container-max-widths: (
        sm: 360px,
        md: 720px,
        lg: 1360px,
        xl: 1610px
);
*/
/* Aleksa Values */
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1400px,
        xxl: 1920px
);
$container-max-widths: (
        sm: 576px,
        md: 720px,
        lg: 960px,
        xl: 1360px,
        xxl: 1610px
);

@function get-breakpoint($tag) {
  @return map-get($grid-breakpoints, $tag);
}

@media (max-width: get-breakpoint("sm")) {
  .container {
    max-width: 100% !important;
  }
}

.h-0 {
  height: 0;
}

.modal-content {
  border: none !important;
}

@import "~bootstrap/scss/bootstrap-utilities";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/transitions";

.container {
  @media (max-width: get-breakpoint("sm")) {
    max-width: 95%;
  }
}

.fullWidth {
  width: 100% !important;
  max-width: 100% !important;
}

@font-face {
  font-family: "Proxima Nova";
  font-display: swap;
  src: url("./Proxima Nova Font.otf") format("opentype");
}

* {
  box-sizing: border-box;
}

html,
body {
  //background: #e5e5e5;
  background: white;
  padding: 0;
  margin: 0;
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, Roboto,
  Segoe UI, Oxygen sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.consent-details {
  text-decoration: underline;
}

#showcase {
  margin-bottom: 5rem;

  > * {
    margin: 5px;
  }
}

.table-outstandingBalance {
  margin: 1rem 0;
  width: 100%;
  max-width: 500px;
  min-width: 200px;
  border-collapse: collapse;

  th {
    font-weight: bold;
    text-align: left;
    background: #d3ccc1;
    padding: 5px 3px;
  }

  tbody > tr {
    td {
      padding: 3px 0;
    }

    td:nth-child(2) {
      width: 80px;
    }
  }

  tbody > tr:nth-last-child(3),
  tbody > tr:nth-last-child(2),
  tbody > tr:nth-last-child(1) {
    background: whitesmoke;
    font-weight: bold;

    td:nth-child(1) {
      text-align: right;
      padding-right: 10px;
    }
  }

  tbody > tr:nth-last-child(1) {
    font-weight: bolder;
  }
}

.table-costEstimate {
  width: 100%;
  max-width: 500px;
  min-width: 200px;
  border-collapse: collapse;

  tbody {
    tr {
      td {
        padding: 5px 0;

        &:nth-child(1) {
          padding-right: 5px;
        }

        &:nth-child(2) {
          vertical-align: top;
          width: 80px;
        }
      }
    }

    tr:last-child {
      font-weight: bolder;
      border-top: 2px solid black;
    }
  }
}

.medicationList {
  & > .row:first-child {
    .row > div,
    & > div:first-child {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  & > .row:not(:first-child) {
    margin-top: 5px;
  }

  & > .row {
    & > div {
      &:nth-child(1),
      &:nth-child(3) {
        width: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:nth-child(2) {
        width: 90%;
      }
    }
  }

  .row:first-child {
  }
}
