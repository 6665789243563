@import "../UI.module";

.dialog {
  padding: 2rem;

  h1 {
    margin-top: 0;

    & > i {
      color: $primaryColor;
    }
  }

  p {
    font-size: 1.1 * $fontSize;
    font-weight: bold;
    white-space: pre-wrap;
  }

  .buttons {
    text-align: center;
    margin-top: 2rem;

    button {
      margin: 0 2px;
    }
  }

  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;

    @include iconButton();
  }
}
