.popup {
  display: flex;
  justify-content: center;

  padding: 2vh 1vw;

  .content {
    overflow-y: auto;

    width: 100%;
    max-width: 100% !important;
    top: 0; // fix
    & > * {
      top: 0; // fix
      position: relative !important;
    }
  }

  //z-index: 9999;
}
